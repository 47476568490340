(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            $programetSlider = $('#programet_slider'),
            $navToggle = $('#nav_toggle'),
            $navWrap = $('#nav_wrap'),
            $topHeader = $('.top_header'),
            $topHeaderHeight = $topHeader.outerHeight(),
            $header = $('#header'),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables
        //Click nav
        $navToggle.click(function(event) {
            $(this).toggleClass('is-active');
            $body.toggleClass('nav-active');
        });
        //Header Scroll
        $doc.scroll(function() {
            if ($doc.scrollTop() >= $topHeaderHeight) {
                $header.addClass('has-scrolled');
            } else {
                $header.removeClass('has-scrolled');
            }
        });
        //Sub Form
        $('.pub_label').click(function(event) {
            var labelid = $(this).attr('for');
            $('#' + labelid).trigger('click');
            $('#pub_filter').serialize();
            $('#pub_filter').submit();
        });
        $('.pub_select').on('change', function() {
            $('#pub_filter').serialize();
            $('#pub_filter').submit();
        });
        //Sub Form
        $('.news_select').on('change', function() {
            $('#news_filter').serialize();
            $('#news_filter').submit();
        });
        //## PLUGINS
        //Programet Slider
        $programetSlider.owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            navText: ['', ''],
            dots: false,
        });
        //INFSCR
        $('.inf-scroll').each(function() {
            var $container = jQuery(this);
            if ($container.attr('data-pagination') == undefined || $container.attr('data-pagination-next') == undefined) {
                throw new "The pagination and pagination next link has to be set";
            }
            if ($container.attr('data-load-more') == undefined) {
                throw new "You have to set the load more button";
            }
            var $itemClass = $container.attr('data-item-class') != undefined ? $container.attr('data-item-class') : '.box-item';
            var $paginationHolder = $container.attr('data-pagination');
            var $paginationNext = $container.attr('data-pagination-next');
            var $button = $container.attr('data-load-more');
            initInfScroll($container, $itemClass, $paginationHolder, $paginationNext, $button);
        });

        function initInfScroll($container, $itemClass, $paginationHolder, $nextSelector, $button) {
            $button = jQuery($button);
            $container.infinitescroll('destroy');
            $container.data('infinitescroll', null);
            // Infinite Scroll
            $container.infinitescroll({
                    // selector for the paged navigation (it will be hidden)
                    navSelector: $paginationHolder,
                    // selector for the NEXT link (to page 2)
                    nextSelector: $nextSelector,
                    // selector for all items you'll retrieve
                    itemSelector: $itemClass,
                    // finished message
                    loading: {
                        finishedMsg: 'No more pages to load.'
                    },
                    animate: false,
                    errorCallback: function() {
                        allLoaded($container, $button);
                    },
                },
                // Trigger Masonry as a callback
                function(newElements) {
                    var lastPage = $button.attr('data-totalPages');
                    var currPage = $container.data('infinitescroll').options.state.currPage;
                    if ($('html').attr('lang') == "en-US") {
                        $button.html('<span>Load More</span>');
                    } else {
                        $button.html('<span>Më Shumë</span>');
                    }
                    if (currPage == lastPage) {
                        allLoaded($container, $button);
                    }
                    $button.removeClass('loading');
                });
            // Resume Infinite Scroll
            $button.click(function() {
                if (!$(this).hasClass('all-loaded')) {
                    $button.addClass('loading');
                    if ($('html').attr('lang') == "en-US") {
                        $button.html('<span>Loading</span>');
                    } else {
                        $button.html('<span>Duke u Ngarkuar</span>');
                    }
                    $container.infinitescroll('retrieve');
                    return false;
                }
            });
        }

        function allLoaded($container, $button) {
            if ($('html').attr('lang') == "en-US") {
                $button.html('<span>All Loaded</span>');
            } else {
                $button.html('<span>Nuk ka artikuj</span>');
            }
            $button.addClass('all-loaded').removeAttr('id');
            $container.infinitescroll('destroy');
        }
        /**
         * OPTIONAL!
         * Load new pages by clicking a link
         */
        // Pause Infinite Scroll
        jQuery(window).unbind('.infscr');
        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d")
    });
}(window.jQuery, window, document));
