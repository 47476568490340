(function ($, window, document) {
    "use strict";

    var app = angular.module("mainApp", ['angular.filter', 'infinite-scroll', 'localytics.directives'], function ($interpolateProvider) {
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');
    });
    app.factory('General', function ($http) {
        var items = [];
        return {
            getVendimi: function getVendimi(data) {
                return $http.get('vendimet', { params: data });
            },
            getSeanca: function getSeanca(data) {
                return $http.get('seanca', { params: data });
            },
            getGjykatesi: function getGjykatesi(data) {
                return $http.get('gjykatesi', { params: data });
            },
            getProkuroret: function getProkuroret(data) {
                return $http.get('prokuroret', { params: data });
            },
            getInfografet: function getInfografet(data) {
                return $http.get('infographet', { params: data });
            },
            update: function update(gjykata) {
                return $http({
                    method: 'POST',
                    url: '/',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: $.param(gjykata)
                });
            }
        };
    });
    app.controller('generalController', function ($scope, $rootScope, $http, $window, $location, $timeout, General) {
        $scope.index = 0;
        $scope.setIndex = function (val) {
            $scope.index = val;
        };
        $scope.page = 1;
        $scope.hasMore = false;
        $scope.onFormSubmit = function () {
            General.getSeanca({
                page: 1,
                gjykata_id: $scope.gjykata_id,
                city_id: $scope.city_id
            }).then(function (response) {
                $scope.seanca = response.data.data;
                $scope.page = 1;
            });
        };
        $scope.onFormSubmit();
    });
    app.controller('seancaController', function ($scope, $http, $window, $location, $timeout, General) {
        $scope.index = 0;
        $scope.setIndex = function (val) {
            $scope.index = val;
        };
        $scope.page = 1;
        $scope.hasMore = false;
        $scope.loading = false;
        $scope.onFormSubmit = function () {
            if (!$scope.loading) {
                $scope.loading = true;
                General.getSeanca({
                    page: 1,
                    gjykata_id: $scope.gjykata_id,
                    gjykatesi_id: $scope.gjykatesi_id,
                    city_id: $scope.city_id,
                    data_seances: $scope.data_seances,
                    vepra: $scope.vepra
                }).then(function (response) {
                    $scope.seanca = response.data.data;
                    $scope.hasMore = response.data.hasMore;
                    $scope.page = 1;
                    $scope.loading = false;
                });
            }
        };
        $scope.onFormSubmit();
        $scope.loadMore = function () {
            if ($scope.hasMore && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                General.getSeanca({
                    page: $scope.page,
                    gjykata_id: $scope.gjykata_id,
                    gjykatesi_id: $scope.gjykatesi_id,
                    city_id: $scope.city_id,
                    data_seances: $scope.data_seances,
                    vepra: $scope.vepra
                }).then(function (response) {
                    angular.forEach(response.data.data, function (seanc) {
                        return $scope.seanca.push(seanc);
                    });
                    $scope.hasMore = response.data.hasMore;
                    $scope.loading = false;
                });
            }
        };
    });
    app.controller('infographetController', function ($scope, $http, $window, $location, $timeout, General) {
        $scope.page = 1;
        $scope.hasMore = false;
        $scope.loading = false;
        $scope.onFormSubmit = function () {
            if (!$scope.loading) {
                $scope.loading = true;
                General.getInfografet({
                    page: 1
                }).then(function (response) {
                    $scope.infographet = response.data.data;
                    $scope.hasMore = response.data.hasMore;
                    $scope.page = 1;
                    $scope.loading = false;
                });
            }
        };
        $scope.onFormSubmit();
        $scope.loadMore = function () {
            if ($scope.hasMore && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                General.getInfografet({
                    page: $scope.page
                }).then(function (response) {
                    angular.forEach(response.data.data, function (infograph) {
                        return $scope.infographet.push(infograph);
                    });
                    $scope.hasMore = response.data.hasMore;
                    $scope.loading = false;
                });
            }
        };
    });
    app.controller('vendimetController', function ($scope, $http, General, $window, $timeout, $location) {
        $scope.page = 1;
        $scope.hasMore = false;
        $scope.loading = false;
        $scope.onFormSubmit = function () {
            General.getVendimi({
                page: 1,
                gjykata_id: $scope.gjykata_id,
                gjykatesi_id: $scope.gjykatesi_id,
                departamenti_id: $scope.departamenti_id,
                data_e_vendimit: $scope.data_e_vendimit,
                lloji_i_rastit: $scope.rasti,
                neni: $scope.neni,
                ligji_id: $scope.ligji,
                statusi: $scope.statusi,
                data_e_vepres: $scope.vepra
            }).then(function (response) {
                $scope.vendimet = response.data.data;
                $scope.page = 1;
                $scope.hasMore = response.data.hasMore;
            });
        };
        $scope.onFormSubmit();
        $scope.loadMore = function () {
            if ($scope.hasMore && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                General.getVendimi({
                    page: $scope.page,
                    gjykata_id: $scope.gjykata_id,
                    gjykatesi_id: $scope.gjykatesi_id,
                    departamenti_id: $scope.departamenti_id,
                    data_e_vendimit: $scope.data_e_vendimit,
                    lloji_i_rastit: $scope.rasti,
                    neni: $scope.neni,
                    ligji_id: $scope.ligji,
                    statusi: $scope.statusi,
                    data_e_vepres: $scope.vepra
                }).then(function (response) {
                    angular.forEach(response.data.data, function (vendim) {
                        return $scope.vendimet.push(vendim);
                    });
                    $scope.hasMore = response.data.hasMore;
                    $scope.loading = false;
                });
            }
        };
    });
    app.controller('gjykatesiController', function ($scope, $http, $window, $location, $timeout, General) {
        $scope.page = 1;
        $scope.gjykatesi = $window.gjykatesi;
        $scope.loading = false;
        $scope.vendimet = [];
        $scope.hasMore = false;
        $scope.count = 0;
        $scope.loadVendimet = function () {
            if (!$scope.loading) {
                $scope.loading = true;
                $http.get($scope.gjykatesi.id + '/vendimet', {
                    params: {
                        page: 1
                    }
                }).then(function (response) {
                    $scope.loading = false;
                    $scope.vendimet = response.data.data;
                    $scope.page = 1;
                    $scope.hasMore = response.data.has_more;
                    $scope.count = response.data.count;
                    console.log($scope.hasMore);
                });
            }
        };
        $scope.loadVendimet();
        $scope.loadMore = function () {
            if (!$scope.has_more && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                $http.get($scope.gjykatesi.id + '/vendimet', {
                    params: {
                        page: $scope.page
                    }
                }).then(function (response) {
                    $scope.loading = false;
                    angular.forEach(response.data.data, function (vendim) {
                        return $scope.vendimet.push(vendim);
                    });
                    $scope.hasMore = response.data.has_more;
                });
            }
        };
    });
    app.controller('gjykatesitController', function ($scope, $http, $window, $location, $timeout, General) {
        $scope.page = 1;
        $scope.has_more = false;
        $scope.loading = false;
        $scope.onFormSubmit = function () {
            if (!$scope.loading) {
                $scope.loading = true;
                General.getGjykatesi({
                    page: 1,
                    gjykata_id: $scope.gjykata_id,
                    gjykatesi_id: $scope.gjykatesi_id
                }).then(function (response) {
                    $scope.gjykatesi = response.data.data;
                    $scope.hasMore = response.data.hasMore;
                    $scope.page = 1;
                    $scope.loading = false;
                });
            }
        };
        $scope.onFormSubmit();
        $scope.loadMore = function () {
            if ($scope.hasMore && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                General.getGjykatesi({
                    page: $scope.page,
                    gjykata_id: $scope.gjykata_id,
                    gjykatesi_id: $scope.gjykatesi_id
                }).then(function (response) {
                    angular.forEach(response.data.data, function (gjykates) {
                        return $scope.gjykatesi.push(gjykates);
                    });
                    $scope.hasMore = response.data.hasMore;
                    $scope.loading = false;
                });
            }
        };
    });
    app.controller('prokuroretController', function ($scope, $http, $window, $location, $timeout, General) {
        $scope.page = 1;
        $scope.has_more = false;
        $scope.loading = false;
        $scope.onFormSubmit = function () {
            if (!$scope.loading) {
                $scope.loading = true;
                General.getProkuroret({
                    page: 1,
                    prokuria_id: $scope.prokuria_id,
                    prokuroret_id: $scope.prokuroret_id
                }).then(function (response) {
                    $scope.prokuroret = response.data.data;
                    $scope.hasMore = response.data.hasMore;
                    $scope.page = 1;
                    $scope.loading = false;
                });
            }
        };
        $scope.onFormSubmit();
        $scope.loadMore = function () {
            if ($scope.hasMore && !$scope.loading) {
                $scope.page++;
                $scope.loading = true;
                General.getProkuroret({
                    page: $scope.page,
                    prokuria_id: $scope.prokuria_id,
                    prokuroret_id: $scope.prokuroret_id
                }).then(function (response) {
                    angular.forEach(response.data.data, function (prokuror) {
                        return $scope.prokuroret.push(prokuror);
                    });
                    $scope.hasMore = response.data.hasMore;
                    $scope.loading = false;
                });
            }
        };
    });
    app.directive("datepicker", function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function link(scope, element, attrs, ngModelCtrl) {
                var updateModel = function updateModel(dateText) {
                    scope.$apply(function () {
                        ngModelCtrl.$setViewValue(dateText);
                    });
                };
                var options = {
                    dateFormat: "yy-mm-dd",
                    onSelect: function onSelect(dateText) {
                        updateModel(dateText);
                    }
                };
                element.datepicker(options);
            }
        };
    });
    app.directive('whenScrolled', function ($window, $timeout) {
        return {
            restrict: "A",
            link: function link(scope, element, attr) {
                var top = angular.element($window)[0].screenTop;
                var origHeight = angular.element($window)[0].screen.height;
                var height = origHeight * 0.9;

                // bind the digest cycle to be triggered by the scroll event
                // when it exceeds a threshold
                angular.element($window).bind('scroll', function () {
                    if (angular.element($window)[0].scrollY >= height) {

                        angular.element($window)[0].requestAnimationFrame(function () {
                            console.log('load more');
                            // invoke the function passed into the 'whenScrolled' attribute
                            scope.$apply(attr.whenScrolled);

                            // increment the threshold
                            height += origHeight * 1.5;
                        });
                    }
                });
            }
        };
    });
})(window.jQuery, window, document);